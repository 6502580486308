import { createSlice } from '@reduxjs/toolkit';

export const block = createSlice({
  name: 'block',
  initialState: {
    openblock: false,
    block: null,
    entity: null,
    entityid: null,
    name: null,
    id: null,
    index: null
  },
  reducers: {
    createBlock() {},
    getBlock() {},
    setBlock() {      
      // return state;
    },    
    openBlock(state, action) {
      const { openblock, block, entity, entityid, name, id, index } = action.payload;
      state.block = block;
      state.openblock = openblock;
      state.entity = entity;
      state.entityid = entityid;
      state.name = name;
      state.id = id;
      state.index = index
    },
    closeBlock(state, action) {
      state.block = null;
      state.openblock = false;
      state.entity = null;
      state.entityid = null;
      state.name = null;
      state.id = null;
      state.index = null
    }
  }
})

export const { openBlock, getBlock, setBlock, createBlock, closeBlock, editBlock } = block.actions;

export default block.reducer;