import axios from "axios";

export default function handleCreateBlock(endpoint){

    const { id, entity, entityid } = endpoint;

    const config = {
        headers: {
            "x-token": localStorage.getItem('token'),
            "Content-Type": "application/json"
        }        
    };


    ///{lang}/{entity}/{id-entity}/slider/{name-slider}/{id-slide}
    
    return axios.put(process.env.REACT_APP_API_URL+'/en/'+entity+'/'+entityid+'/visual/blocks_visual_blocks/'+id, config)

}