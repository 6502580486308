import { takeLatest } from "redux-saga/effects";
import { handleListing } from "./handlers/listing";
import { getListing } from "../slices/listing";
import { handleSingle } from "./handlers/single";
import { getSingle } from "../slices/single";
import { handleCreateSingle } from "./handlers/createsingle";
import { createSingle } from "../slices/single";
import { getPostlist } from "../slices/postlist";
import { handlePostlist } from "./handlers/postlist";
import { getPostlistvalue } from "../slices/postlistvalue";
import { handlePostlistvalue } from "./handlers/postlistvalue";
import { createPostlistvalue } from "../slices/postlistvalue";
import { handlePostlistCreatevalue } from "./handlers/createpostlistvalue";
import { getSlide } from "../slices/slider";
import { handleSlider } from "./handlers/slider";
import { createSlide } from "../slices/slider";
import { handleCreateSlider } from "./handlers/createslider";
import { createBlock } from "../slices/block";
import { handleCreateBlock } from "./handlers/createblock";

export function* watcherSaga() {
    yield takeLatest(getListing.type, handleListing);
    yield takeLatest(getSingle.type, handleSingle);
    yield takeLatest(createSingle.type, handleCreateSingle);
    yield takeLatest(getPostlist.type, handlePostlist);
    yield takeLatest(getPostlistvalue.type, handlePostlistvalue);
    yield takeLatest(createPostlistvalue.type, handlePostlistCreatevalue);
    yield takeLatest(getSlide.type, handleSlider);
    yield takeLatest(createSlide.type, handleCreateSlider);
    yield takeLatest(createBlock.type, handleCreateBlock);
};
